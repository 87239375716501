
import '../Common.css'
import React from 'react';
import Table from '../Table';
import './IndoorSavingsTable.css'
import NumberFormat from '../NumberFormat';
import CurrencyFormat from '../CurrencyFormat';

const IndoorSavingsTable = ({ url }) => {

 const tableSortees = [{
    id: "Name",
    desc: false
  }];

  const tableColumns = [
      {
        Header: 'Property',
        accessor: 'Name',
        Cell: ({ row: { index }, value }) => 
        <div>{value}</div>  // accessor is the "key" in the data
      },
      {
        Header: 'Issues Detected',
        accessor: 'IssuesDetected',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <NumberFormat data={value}></NumberFormat>
            </>
          );
        }
      },
      {
        Header: 'Gallons Saved',
        accessor: 'WaterSavings',
        Cell: ({ cell: { value } }) => {
          return (
            <>
             <NumberFormat data={value}></NumberFormat>
            </>
          );
        }
      },
      {
        Header: 'Dollars Saved',
        accessor: 'CostSavings',
        Cell: ({ cell: { value } }) => {
          return (
            <>
              <CurrencyFormat data={value}></CurrencyFormat> 
            </>
          );
        }
      },
    ];
    
  return (
        <div className='table-container-indoor'>
          <Table url={url} 
            title='Indoor Leak Cost Avoidance'
            columns={tableColumns} 
            sortees={tableSortees} />
        </div>
  );
}


export default IndoorSavingsTable;