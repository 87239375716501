import { useEffect, useContext, useState } from 'react';
import { Context } from '../../context/AuthContext';
import api from '../../api/api';
import './IndoorSavingsDrilldownWidget.css';
import '../assetDashboardHomeIcons/WaterSavingsRollup.css'
import '../Common.css';
import GallonsSaved from '../GallonsSaved';
import NetSavings from '../NetSavings';
import GrossSavings from '../GrossSavings';
import LoadingSpinner from '../LoadingSpinner';

export default function IndoorSavingsDrilldownWidget({ data, callback }) {
  const [isLoading, setIsLoading] = useState(true);
  const { state } = useContext(Context);
  const token = state.token;
  const startDate = state.startDate;
  const endDate = state.endDate;
  const accountId = state.accountId;

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      fetchData();
    })();
  }, [startDate, endDate]);

  const fetchData = () => {
    api.getData(startDate, endDate, accountId, 'AssetDashboard/IndoorSavingsRollupNumbers', token)
      .then(response => {
        callback(response.data.Result);
        setIsLoading(false);
      }).catch(error => {

      });
  };

  const waterdrop = require('../../../src/assets/images/water-drop-with-white-fill.png');

  if (isLoading) return <LoadingSpinner />;
  return (
    <div className="widget-drilldown-indoor">
      <div className="water-droplet-container">
            <img src={waterdrop} alt="water-drop"/>
            <div className="overlay-text"><GrossSavings waterSavingsResponseData={data.TotalSavingsInDollars}></GrossSavings></div>
          </div>
          <div className="water-droplet-container">
            <img src={waterdrop} alt="water-drop"/>
            <div className="overlay-text"><NetSavings waterSavingsResponseData={data.NetSavingsInDollars}></NetSavings></div>
          </div>
      <div className="water-droplet-container">
            <img src={waterdrop} alt="water-drop"/>
            <div className="overlay-text"><GallonsSaved waterSavingsResponseData={data.TotalWaterSavingsInGallons}></GallonsSaved></div>
          </div>
        </div>
  );
}
