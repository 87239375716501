import './Common.css' 
import CurrencyHeader from './CurrencyFormat'

export default function GrossSavings({waterSavingsResponseData}){

 return(   
    <div className='common-panel'>
    <span className='water-drop-title'>
    <CurrencyHeader data={waterSavingsResponseData}></CurrencyHeader> 
    </span>
    <span className='common-panel-text'> Gross Savings </span>
  </div>
 )
}