import React from 'react';
import './SelectProduct.css';
import { useNavigate } from 'react-router-dom';
import { Context } from "../context/AuthContext";
import { useContext } from "react";

export default function SelectProduct() {
    const navigate = useNavigate();
    const { signout } = useContext(Context);

    const handleLogout = () => {
        window.localStorage.removeItem("rememberMe");
        localStorage.clear();
        signout();
        navigate('');
    };


    return (
        <div>
        <div className="home-wrapper-sp">
            <div className="home-tile-sp" onClick={() => navigate("/landing")}>Asset Dashboard</div>
            <div className="home-tile-sp" onClick={() => navigate("/leakcostavoidance")}>Leak Cost Avoidance</div>
        </div>
        <button onClick={handleLogout}>Logout</button>
        </div>
    )
}
