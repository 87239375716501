import React, { useState, useContext, useEffect } from 'react';
import { useSortBy, useGlobalFilter } from 'react-table';
import { useTable } from 'react-table/dist/react-table.development';
import GlobalFilter from './GlobalFilter';
import ExcelJs from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import './Table.css';
import { Context as userContext } from "../context/AuthContext";
import api from '../api/api';
import LoadingSpinner from './LoadingSpinner';

export default function Table({ columns, title, url, callback, sortees,
  updateData = () => { }, defaultData = [], scrollXMax = null, fileName = null, isES = false }) {
  const rowsPerRequest = 20;

  const [isLoaded, setIsLoaded] = useState(false);
  const [data, setData] = useState(defaultData);
  const [tableData, setTableData] = useState(defaultData);
  const [skip, setSkip] = useState(0);
  const [showAllRecords, setShowAllRecords] = useState(false);

  const { state: userState } = useContext(userContext)
  const token = userState.token;
  const startDate = userState.startDate;
  const endDate = userState.endDate;
  const accountId = userState.accountId;

  const updateShowAllRecords = () => {
    setShowAllRecords(true);
    setTableData(data);
  };

  useEffect(() => {
    (async () => {
      setIsLoaded(false);
      await fetchData(0, false);
    })();
  }, [startDate, endDate]);

  useEffect(() => {
    (async () => {
      await fetchData(skip);
    })();
  }, [skip]);

  const fetchData = (skipValue, loaded = isLoaded) => {
    if (defaultData.length > 0) {
      setIsLoaded(true);
      return;
    }

    api.getTableData(startDate, endDate, accountId, skipValue, url, token)
      .then((response) => {
        if (!loaded) {
          setTableData(response.data);
          setData(response.data);
          setIsLoaded(true);
        } else {
          setData([...data, ...response.data]);
        }

        if (showAllRecords) {
          setTableData([...data, ...response.data]);
        }
        if (response.data.length === rowsPerRequest) {
          setSkip(skipValue + rowsPerRequest);
        }
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const getXlsxHeadersAndKeys = (title, props) => {
    switch (title) {
      case "Portfolio Water Savings": return [
        { header: "PropertyName", key: "PropertyName" },
        { header: "BaselineUsage", key: "BaselineUsage" },
        { header: "BaselineCost", key: "BaselineCost" },
        { header: "Usage", key: "Usage" }, { header: "Cost", key: "Cost" }];
      case "Contract to Date Water Savings": return [
        { header: "PropertyName", key: "PropertyName" },
        { header: "InstallDate", key: "InstallDate" },
        { header: "InstallationCost", key: "CapEx" },
        { header: "GallonsSaved", key: "WaterSavings" },
        { header: "DollarsSaved", key: "CostSavings" },
        { header: "OpexCost", key: "OpEx" },
        { header: "LeakCostAvoidance", key: "LeakCostAvoidance" },
        { header: "NetSavings", key: "NetSavings" }];
      case "Indoor Leak Cost Avoidance": return [
        { header: "PropertyName", key: "PropertyName" },
        { header: "IssuesDetected", key: "IssuesDetected" },
        { header: "GallonsSaved", key: "WaterSavings" },
        { header: "DollarsSaved", key: "CostSavings" }];
      default: return props.map(prop => ({ header: prop, key: prop }));
    }
  }

  const exportExcelData = () => {
    const workbook = new ExcelJs.Workbook();
    const worksheet = workbook.addWorksheet(title);
    const columns = [];
    //set column headers
    const columnData = getXlsxHeadersAndKeys(title, Object.keys(tableData[0]));
    columnData.forEach(c => {
      let type = 'string';
      let style;
      // set type and style for column with numbers
      if (!isNaN(tableData[0][c.key])) {
        type = 'number';
        if (c.key.toLowerCase().includes("cost")) {
          style = { numFmt: '$#,##0.00' }
        } else if (c.key.toLowerCase().includes("percentage")) {
          style = { numFmt: '##0%' }
        } else {
          style = { numFmt: '#,##0.00' }
        }
      }
      columns.push({ header: c.header, key: c.key, width: 15, type: type, style: style });
    });
    worksheet.columns = columns;
    tableData.forEach(data => {
      const rowData = [];
      columnData.forEach(c => {
        rowData.push(data[c.key]);
      });
      worksheet.addRow(rowData);
    })
    workbook.xlsx.writeBuffer({ useStyles: true })
      .then(buffer => saveAs(new Blob([buffer]), `${moment().format('MMDDYYYY')} ${title || "output"}.xlsx`))
      .catch(err => console.log("Error", err));
  }

  const { getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter } = useTable(
      {
        columns,
        data: tableData,
        // initialState: sortees &&
        // {
        //   sortBy: sortees
        // }
      },
      useGlobalFilter,
      useSortBy,
      updateData);

  const printer = require('../../src/assets/images/printer.png');

  if (!isLoaded) return <LoadingSpinner />;

  return (
    <div className='table-container'>
      <div className='table-header'>
        <div className='filter-container'>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            isES={isES}
          />
        </div>
        {title}
        <button className='button-ws' onClick={exportExcelData}>
          <span className='image-container'>
            <img
              src={printer}
              alt="Excel"
              className="printer-image"
            ></img>
          </span>
          <span className='button-text-ws'>{isES ? "Tabla de Exportación" : "Export Table"}</span>
        </button>
      </div>
      <div style={scrollXMax != null ? { width: scrollXMax, overflowX: 'auto' } : null}>
        <table {...getTableProps()} className='table'>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      background: '#034C8C',
                      border: 'solid 1px gray',
                      color: 'white',
                      fontWeight: 'bold',
                      fontFamily: 'Raleway, san-serif',
                      minWidth: 40,
                      width: column.Header === "" ? null : 400,
                      cursor: 'pointer'
                    }}
                  >
                    {column.render('Header')}
                    <span>
                      {column.isSorted
                        ? column.isSortedDesc
                          ? ' 🔽'
                          : ' 🔼'
                        : ''}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, index) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} onClick={() => callback(row, index, data)}>
                  {row.cells.map(cell => {
                    return (
                      <td className='table-cell'
                        {...cell.getCellProps()}
                        style={{
                          padding: '10px',
                          border: 'solid 1px gray',
                          color: 'black',
                          background: cell.row.index % 2 === 0 ? 'white' : '#EAF5FF',
                          fontFamily: 'Raleway, san-serif',
                          cursor: 'pointer'
                        }}
                      >
                        {cell.render('Cell')}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
      {data.length > 20
        ? <div className='table-show-more' onClick={() => updateShowAllRecords()}>
          <span className='plus'>+</span> {isES ? "Mostrar todos los datos" : "Show all records"}
        </div>
        : null}
    </div>
  );
}
