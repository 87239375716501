import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { Context as userContext } from '../../../context/AuthContext';
import api from '../../../api/api';
import LoadingSpinner from '../../LoadingSpinner';
import "./ImportEstimatedBillsForm.css"

export const ImportEstimatedBillsForm = ({ irrigatableArea, utilityRateId, updateBillData, handleClearBillData }) => {
    const { state: userState } = useContext(userContext);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedRegion, setSelectedRegion] = useState('');
    const [estimatedRegions, setEstimatedRegions] = useState([]);
    const [errorMsg, setErrorMsg] = useState("");

    useEffect(() => {
        (async () => {
            try {
                const res = await api.getEstimatedBaselineRegions(userState.token);
                const mappedData = res.data.map(item => {
                    return {
                        value: item,
                        label: item
                    }
                })
                setEstimatedRegions(mappedData);
                setSelectedRegion(mappedData[0].value);
                setIsLoading(false);
            } catch (e) {
                console.log(e);
                setErrorMsg("Something went wrong...");
            }
        })();
    }, [userState.token])

    const handleImport = async () => {
        if (selectedRegion === '') {
            setErrorMsg("Please select a region.");
        } else {
            try {
                setErrorMsg('');
                setIsLoading(true);
                console.log(selectedRegion.value)
                const res = await api.getEstimatedBillsFromRegion(userState.token, selectedRegion.value, irrigatableArea, utilityRateId);
                const mappedEstimatedBills = res.data.map(b => ({
                    MeterNumber: b.MeterNumber,
                    Start: b.Start,
                    End: b.End,
                    UsageVolume: b.UsageVolume,
                    TotalCost: b.TotalCost,
                    SewerAmount: b.SewerAmount,
                    OtherAmount: b.OtherAmount,
                }))
                handleClearBillData();
                updateBillData(mappedEstimatedBills);
                if (mappedEstimatedBills.length > 0) {
                    setErrorMsg('Import Completed');
                } else {
                    setErrorMsg('Something went wrong...');
                }
            } catch(e) {
                console.log(e);
                setErrorMsg("Something went wrong...");
            }
            setIsLoading(false);
        }
    }

    return (
        <div className="modal-wrapper">
            {isLoading ?
                <div className="modal-spinner-wrapper"><LoadingSpinner /></div> :
                <div className="modal-wrapper">
                {errorMsg.length > 0 && <div>{errorMsg}</div>}
                <div className='du-select-container'>
                <Select 
                    placeholder='Pick a region...'
                    menuPlacement='auto'
                    defaultValue={selectedRegion}
                    value={selectedRegion}
                    onChange={(val) => setSelectedRegion(val)}
                    options={estimatedRegions} 
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 20,
                        colors: {
                            ...theme.colors,
                            neutral0: '#EAF5FF',
                            neutral20: 'rgba(94, 101, 94, 1)',
                            primary: 'rgba(94, 101, 94, 1)',
                        }, 
                    })} 
                />
                </div>
                <button
                    className="proposal-form-btn proposal-form-submit-btn"
                    onClick={handleImport}>Import</button>
                </div>
            }
        </div >
    )
}
