import createDataContext from './createDataContext';
import login from '../api/login';

const setAccountId = dispatch => async (accId) => {
  dispatch({ type: 'accountId', payload: accId })
};

const setPropertyName = dispatch => async (propName) => {
  dispatch({ type: 'propertyName', payload: propName })
};

const setPropertyId = dispatch => async (propId) => {
  dispatch({ type: 'propertyId', payload: propId })
};

const logInWithEmail = dispatch => async (email, password) => {
    const data = await login(email, password)
    .then(async (result) => {
      const data = result.data;
      const token = data.access_token;
      const payload = {
        token, email
      };

      dispatch({ type: 'token', payload });
      return data;
    })
    .catch(error => {
      const payload = {
        email, token: ''
      };

      dispatch({ type: 'token', payload });
      return error;
    });
    return data;
};

const setIsAdmin = dispatch => async (isAdmin) => {
  const payload = {
    isAdmin
  };
  dispatch({ type: 'isAdmin', payload })
}

const setDates = dispatch => async (startDate, endDate, dateRange) => { 
    const payload = {
      startDate, endDate, dateRange
    };
    dispatch({ type: 'dates', payload });

};

const setToken = dispatch => async (email, token) => { 
  const payload = {
    email, token
  };
  dispatch({ type: 'token', payload });

};

const authReducer = (state, action) => {
  switch (action.type) {
    case 'token':
        return { 
            ...state, 
            token: action.payload.token, 
            email: action.payload.email 
        };

    case 'signout':
        return { 
          ...state, 
          email: '', 
          token: '',
          isAdmin: false,
          isIrr: false,
          isIndoor: false,
        };
    case 'dates':
        return {
          ...state,
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
          dateRange: action.payload.dateRange
        };
    case 'isAdmin':
        return {
          ...state,
          isAdmin: action.payload.isAdmin.isAdmin,
          isIrr: action.payload.isAdmin.isIrr,
          isIndoor: action.payload.isAdmin.isIndoor
        };
    case 'propertyName':
        return {
          ...state,
          propertyName: action.payload
        };
    case 'propertyId':
        return {
          ...state,
          propertyId: action.payload
        };
    case 'accountId':
        return {
          ...state,
          accountId: action.payload
        };
    default:
        return state;
  }
};

const signout = dispatch => () => {
    dispatch({ type: 'signout' });
};

export const { Provider, Context } = createDataContext(
  authReducer,
  { signout, logInWithEmail, setDates, setIsAdmin, setToken, setPropertyName, setPropertyId, setAccountId },
  { token: '', email: '' },
  { startDate: new Date(), endDate: new Date(), propertyName: '', propertyId: 0, accId: 0 },
  { isAdmin: false, isIrr: false, isIndoor: false }
);

